<template>
  <app-modal-transaction-detail
    :show="showModalDetail"
    @close="showModalDetail = false"
    :transaction="selectTransaction"
    :showBusiness="true"
    @update:transaction="selectTransaction = $event"
    routeName="support.balance.transaction.payment-link"
  />
  <app-table
    :apiResponse="apiPaginationResponse?.data ?? []"
    :loading="loading"
    @params-changed="paramsChanged"
    :showEmptyButton="false"
    :filters="[
      {
        key: 'filter[business_name]',
        type: 'text',
        placeholder: $t('payout.table.account.header_business_name'),
      },
      {
        key: 'filter[amount]',
        type: 'text',
        placeholder: $t('payout.table.account.header_amount'),
      },
      {
        key: 'filter[type]',
        type: 'select',
        placeholder: 'Type',
        items: [
          {
            id: TRANSACTION_TYPE.TRANSFER_IN,
            name: $t('wallet.transaction.type_transfer_in'),
          },
          {
            id: TRANSACTION_TYPE.TRANSFER_OUT,
            name: $t('wallet.transaction.type_transfer_out'),
          },
          {
            id: TRANSACTION_TYPE.SETTLED_IN,
            name: $t('wallet.transaction.settled_in'),
          },
          {
            id: TRANSACTION_TYPE.SETTLED_OUT,
            name: $t('wallet.transaction.settled_out'),
          },
          {
            id: TRANSACTION_TYPE.PENDING,
            name: $t('wallet.transaction.pending'),
          },
        ],
      },
      {
        key: 'filter[created_at_between]',
        type: 'date',
        placeholder: $t('wallet.transaction.date'),
        value: '',
      },
    ]"
    @on-item-click="onItemClick"
  >
    <template v-slot:header>
      <th class="font-bold">{{ $t("wallet.transaction.business_name") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.amount") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.type") }}</th>
      <th class="font-bold">{{ $t("wallet.transaction.date") }}</th>
    </template>
    <template v-slot:body="slotData">
      <td class="">
        {{ slotData.model.business?.name ?? "-" }}
      </td>
      <td class="flex place-items-center space-x-2 justify-between">
        <p
          class="font-medium"
          :class="{
            'text-success': slotData.model.type?.includes('_in'),
            'text-error': slotData.model.type?.includes('_out'),
          }"
        >
          {{
            $formats.currency(
              slotData.model.currency,
              slotData.model.amount ?? 0.0
            )
          }}
        </p>
      </td>
      <td>
        <p class="capitalize">
          {{ slotData.model.type?.replace("_", " ") }}
        </p>
      </td>
      <td>
        {{ $moment(slotData.model.created_at).format("DD MMM YYYY hh:mm a") }}
      </td>
    </template>
  </app-table>
</template>
<script>

import TRANSACTION_TYPE from "@/utils/const/transaction_type";

export default {
  data() {
    return {
      filters: {},
      showModalDetail: false,
      selectTransaction: null,
      TRANSACTION_TYPE: TRANSACTION_TYPE,
    };
  },

  mounted() {
    this.$store.dispatch("walletStore/getTransactionForSupport", this.filters);
  },

  computed: {
    apiPaginationResponse() {
      return this.$store.getters["walletStore/apiPaginationResponse"];
    },

    loading() {
      return this.$store.getters["walletStore/loading"];
    },
  },

  methods: {
    paramsChanged(filters, queryParams) {
      this.filters = filters;
      this.filters["filter[type]"] = filters["filter[type]"]?.id;
      this.filterFormatDate(filters["filter[created_at_between]"]);
      this.$store.dispatch(
        "walletStore/getTransactionForSupport",
        this.filters
      );
    },

    filterFormatDate(date) {
      let start = date?.start_date;
      let end = date?.end_date;

      if (date) this.filters["filter[created_at_between]"] = start + "," + end;
    },

    onItemClick(data) {
      this.showModalDetail = !this.showModalDetail;
      this.selectTransaction = data;
    },
  },
};
</script>